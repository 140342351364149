@media only screen and (max-width: 1000px) {
	.app-header .container {
		flex-wrap: wrap;
	}
	
	.menu-toggle {
		display: block;
	}
	
	.main-nav {
		position: absolute;
		left: 1rem;
		top: 7.5rem;
		/* bottom: 0; */
		
		width: 100%;
		min-height: calc(100vh - 7.75rem);
		flex-direction: column;
		align-items: flex-start;
		
		margin: 0 -1rem 1rem;
		padding-bottom: 1rem;
		background-color: var(--colorCiffc);
		
		font-size: 1.2rem;
		
		transform: translateX(-100vw);
	}
	.menu-on .main-nav {
		transform: translateX(0);
	}
	.menu-on ~ main,
	.menu-on ~ footer {
		max-height: 0;
		overflow: hidden;
	}
	
	.app-header .language-toggle {
		position: fixed;
		top: -5rem;
		right: 5rem;
		width: auto;
		color: var(--colorCiffc);
		font-weight: 700;
		transform: translateX(100vw);
	}
	.app-header.menu-on .language-toggle {
		transform: translateX(0);
	}
	.app-header .language-toggle .link {
		color: inherit;
	}
	
	
	.nav-list {
		flex-direction: column;
		border: none;
		margin: 0;
		padding: 0;
		width: 100%;
		background-color: var(--colorCiffc);
		color: #fff;
	}
	.nav-list.primary-list {
		margin: 0;
		
	}
	.nav-list.secondary-list {
		position: static;
		order: 0;
		font-size: inherit;
	}
	.nav-list li {
		display: block;
		width: 100%;
		padding: 0;
		border-top: 1px solid #ffffff66;
	}
	.nav-list li a,
	.nav-list li .link {
		color: #fff;
		padding: .5rem 1rem;
		width: 100%;
		text-align: left;
	}
	.nav-list li a:hover,
	.nav-list li .link:hover {
		color: #000;
	}
	.nav-list .nav-list a,
	.nav-list .nav-list .link { 
		padding-left: 3rem;
		color: #fffe;
	}
	
	.agency-sidebar-navigation,
	.app-header li:hover > .agency-sidebar-navigation {
		display: flex;
		position: relative;
		right: auto;
		width: 100%;
		border: none;
		
		background-color: #fffc;
		color: var(--colorCiffc);
		
		opacity: 1;
	}
	.agency-sidebar-navigation::before {
		content: none;
		left: 1.75rem;
		right: auto;
	}
	.nav-list .agency-sidebar-navigation a,
	.nav-list .agency-sidebar-navigation .link {
		color: var(--colorCiffc);
	}
	
	.app-header li a.pill-shape,
	.app-header li a.pill-shape.logged-in {
		border-radius: 0;
		margin: 0rem 0 0 0;
		padding: .5rem 1rem;
		border: none;
		background-color: inherit;
		font-weight: 800;
	}
	.app-header li a.pill-shape.logged-in {
		background-color: #fffc;
	}
	
	table tr:nth-child(even) {
		background-color: transparent; /* Remove zebra styles to allow overscroll visual cues on table-wraps */
	}

	.dashboard-map-legend {
		max-width: 25%;
	}
	.map-legend ul {
		margin-left: 1rem;
	}
}

@media only screen and (max-width: 800px) {

	.dashboard-map {
		flex-direction: column;
	}

	.dashboard-map-legend {
		position: relative;
		inset: auto;

		max-width: 100%;
		padding: 1rem .5rem 1rem;
	}
	
	.dashboard-card {
		justify-content: center;
		flex-direction: column;
		gap: 1rem;
	}
		.dashboard-card-summary { 
			width: 90%;
		}

	.map-title {
		width: auto;
	}

	.map-legend {
		display: flex;
		justify-content: center;
		gap: 2rem;

		margin: 0 auto;
		width: 100%;
	}

		.map-legend .map-legend-item {
			margin-bottom: 0.5rem;
			white-space: wrap;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		.map-legend .map-legend-item svg {
			margin: 0.25rem auto;
		}

	.map-legend > li {
		white-space: nowrap;
		width: 22%;
		text-align: center;
	}
	.map-legend ul {
		margin-left: 0;
		margin: 0 auto;
		width: auto;
		display: inline-block;
		text-align: left;
	}
	.map-legend ul li {
		
	}

}


@media only screen and (max-width: 600px) {
	.app-header h1 {
		width: 40%;
	}
	.app-header h1 svg {
		width: 100%;
	}
	
	
	.dashboard-row {
		flex-direction: column;
		width: auto;
	}
	.dashboard-column {
		width: 100%;
		flex-grow: 1;
	}
	
	.dashboard-card > canvas {
		width: 85% !important;
	}
	
	.dashboard.agency .agency-status-summary dt {
		width: 50%
	}
	
	.map-legend {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		gap: 1rem;
		width: auto;
    	margin: 1rem auto;
		
	}
	
		.map-legend > li {
			width: 45%;
		}
	
	.more-label {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	.table-filter > div {
		margin-right: 0;
		width: 100%;
	}
	table {
		border-color: #ccc;
	}
	
	
	.table-sidebyside {
		flex-direction: column;
		flex-wrap: nowrap;
	}
	
	
	.form-status dt {
		width: 35%;
	}
	.form-status dd {
		width: 55%;
		margin-bottom: .5rem;
	}
	
	.input-series-add-with-fields {
		flex-direction: column;
	}
	.input-series-add-with-fields .input-set {
		flex-direction: column;
		flex-wrap: wrap;
	}
	.input-series-add-with-fields > div.input {
		min-width: auto;
		width: 100%;
	}
	.input-series-add-with-fields select {
		width: 100%;
	}
	.input-series-add-with-fields .fa-chevron-right {
		transform: rotate(90deg);
	}
	
	.form-section fieldset.input > div.input {
		width: fit-content;
		margin-right: 1rem;
	}
	.form-section fieldset.input > div.input.thin {
		width: min-content;
		margin-right: 1rem;
	}
	
	form button.link {
		padding: .75rem 1rem;
	}
	form legend button.link {
		margin-top: 1rem;
	}
	
	.input-series .input-series-remove {
		/* display: block; */
		position: relative;
		inset: 0;
		
		display: flex;
		padding: .75rem 1rem;
		margin: .75rem 0 .5rem !important;
		border: 0;
		border-radius: 1rem;
		background: #eee;
		/* color: black; */
		font-size: 1rem;
		/* text-decoration: underline; */
		cursor: pointer;
	}
		.input-series .input-series-remove .button-label {
			border: 0;
			clip: unset;
			height: auto;
			margin: 0;
			overflow: hidden;
			padding: 0;
			position: static;
			width: auto;
			
			font-size: 1rem;
		}
	.input-series.without-duplicate-labels .input-series-remove {
		top: 0;
	}
	
	
	input,
	select,
	textarea,
	output {
		font-size: 16px;
	}
	
	.button {
		font-size: 1rem;
		padding: 0.75rem 1.75rem;
		border-radius: 1.5rem;
	}

	main hgroup {
		display: block;
	}
	
	#root > footer .container {
		flex-direction: column;
	}
	#root > footer .contacts {
		order: -1;
		margin: 0 auto .5rem;
	}
	#root > footer .contacts svg {
		font-size: 2rem
	}
}