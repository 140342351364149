@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* closest google font equvilent to franklin gothic */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
    --colorCiffc: #F5333F; /* panatone 032c */

    --colorBody: #f2f6f5;

    --colorRed: #900;
    --colorPink: #f9f0f3;

    --colorGreen: #090;
    --colorLightGreen: #f0f9f3;

    --colorBlue: #18b4cd;
    --colorLightBlue: #fcfcff;

    --colorApprovalDefault: #eee;
    
    --colorApprovalUnsubmitted: #ccc;
    --colorApprovalEdit: #18b4cd;
    --colorApprovalSubmit: #FFC431;
    --colorApprovalReject: #F5333F;
    --colorApprovalApprove: #5EB546;
    --colorApprovalUnpublished: #18b4cd;
    --colorApprovalPublished: #FFC431;
    
    --colorApprovalHover: #200;

    --boxShadow: 0 0.25rem 0.5rem #3311330c;
}

.nl2br {
    white-space: pre-line;
}

.spinner {
    display: block;
    margin: 0 auto;
    font-size: 3rem;
    color: var(--colorCiffc);
}
.spinner.api-check {
    display: none;
}
    .spinner.api-check ~ *:not(.back) {
        animation: Pulsate 4s linear infinite;
        pointer-events: none;
    }
    @keyframes Pulsate {
      from { opacity: 1; }
      50% { opacity: 0.2; }
      to { opacity: 1; }
    }


a { color: inherit; }
a:hover { color: #000; }

.apl-lvl-1 { background-color: #18b4cd; }
.apl-lvl-1-light { background-color: #18b4cd33; }
.apl-lvl-2 { background-color: #5EB546; }
.apl-lvl-2-light { background-color: #5EB54633; }
.apl-lvl-3 { background-color: #FFC431; }
.apl-lvl-3-light { background-color: #FFC43144; }
.apl-lvl-4 { background-color: #F9A123; }
.apl-lvl-4-light { background-color: #F9A12344; }
.apl-lvl-5 { background-color: #F5333F; }
.apl-lvl-5-light { background-color: #F5333F33; }

    [class*="apl-lvl-"] {
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
    }

    [class*="apl-lvl-"][class*="-light"] {
        color: #000000aa;
        font-weight: 600;
    }

    span[class*="apl-"],
    span[class*="apl-"],
    span[class*="apl-"] {
        margin: 0 0.25em;
        padding: 0.125em 0.5em 0.05em;
        border-radius: 0.5em;

        color: white;
        font-weight: 700;

        text-shadow: 0 0 1rem #00000022;
    }

body {
    background-image: url(/blurry.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: var(--colorBody);

    color: #000;
    font-size: 1rem;
    font-family: 'Libre Franklin', Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.4;
}

    #root {
        /* border-top: 0.5rem solid var(--colorCiffc); */
        background: #ffffff66;
    }

    .container {
        position: relative;
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        padding: 0 1rem;
    }


form {}

    form.form-submitting {
        opacity: 0.5;
        cursor: wait !important;
    }

        form.form-submitting * {
            pointer-events: none;
            cursor: wait !important;
        }

        .form-status .error {
            padding: 0 0.25rem;
            background-color: var(--colorPink);

            color: var(--colorRed);
            font-style: italic;
        }

        .form-status .syncing {
            padding: 0 0.25rem;
            background-color: #0000ff22;

            color: #0000cc;
            font-style: italic;
        }

        .form-status .editing {
            padding: 0 0.25rem;
            background-color: var(--colorLightBlue);

            color: var(--colorBlue);
            font-style: italic;
        }

    .form-submit-buttons {
        display: flex;
        gap: 2rem;

        margin: 4rem 0 0;
    }

        .form-submit-buttons .button-submit {
            appearance: none;

            display: flex;
            align-items: center;

            padding: 1rem 4rem;
            border: 0;
            border-radius: 1rem;
            background-color: #eee;

            color: white;
            font-size: 1.2rem;
            line-height: 1.2;
            font-weight: 600;

            cursor: pointer;
        }

            .form-submit-buttons .button-submit.submitted {
                background: var(--colorApprovalSubmit);

                color: black;
            }

            .form-submit-buttons .button-submit.approved {
                background: var(--colorApprovalApprove);

                color: white;
            }

            .form-submit-buttons .button-submit.rejected {
                background: var(--colorApprovalReject);

                color: white;
            }

            .form-submit-buttons.unpublished {
                background: var(--colorApprovalUnpublished);

                color: black;
            }

            .form-submit-buttons .button-submit.published {
                background: var(--colorApprovalPublished);

                color: black;
            }

            .form-submit-buttons .button-submit[disabled] {
                cursor: not-allowed;
            }

            .form-submit-buttons .button-submit:not([disabled]):hover {
                background: black;

                color: white;
            }

            .form-submit-buttons .button-submit [class*="fa-"] {
                margin-right: 0.75rem;

                font-size: 2rem;
            }

    form button.link {
        display: inline-block;
        padding: .5rem 0.75rem;
        border: 0;
        border-radius: 1rem;
        background: #eee;
        
        color: black;
        font-size: 0.875rem;
        text-decoration: underline;

        cursor: pointer;
    }

        form button.link:hover {
            background: black;

            color: white;
        }

        form legend button.link {
            margin-left: 1rem;
        }

    div.input:not(.input-set) {
        margin: 0 0 0.5rem;
    }

        .input[data-validation]:not([data-validation=""]) {}

            .input[data-validation]:not([data-validation=""]) > legend,
            .input[data-validation]:not([data-validation=""]) > label,
            .input[data-validation]:not([data-validation=""]) > .input > input[type="radio"] ~ label {
                color: var(--colorRed);
            }

            .input[data-validation]:not([data-validation=""]) > input,
            .input[data-validation]:not([data-validation=""]) > .input > input[type="radio"],
            .input[data-validation]:not([data-validation=""]) > select,
            .input[data-validation]:not([data-validation=""]) > textarea {
                border-color: var(--colorRed);

                color: var(--colorRed);
            }

            .input[data-validation]:not([data-validation=""]):after {
                content: attr(data-validation);

                flex-grow: 1;
                
                display: block;
                width: 100%;
                margin: 0.25rem 0 0;
                padding: 0.25rem 0.5rem 0;
                background-color: var(--colorPink);

                color: var(--colorRed);
                font-family: sans-serif;
                font-size: 0.75rem;
                /* line-height: 2; */
                font-style: italic;
            }

            .input[data-validation]:not([data-validation=""]):empty:after {
                margin: 1rem 0;
                padding: 0.5rem 1rem;
                /*
                background-color: var(--colorRed);

                color: white;
                */
                font-size: 1rem;
                font-style: normal;
            }

        div.input.submit {
            display: flex;
            margin-top: 2rem;
        }

    label, legend {
        color: inherit;
        font-size: 1rem;
        font-family: 'Libre Franklin', Helvetica, Arial, sans-serif;
        font-weight: 700;

        cursor: inherit;
    }

        input[type="radio"] {
            display: inline-block;
        }

        input[type="radio"] + label {
            display: inline-block;
            
            font-weight: 400;  
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            display: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type=number] {
            -moz-appearance: textfield;
            /* Firefox */
        }

    .label-info {
        display: block;

        font-size: 0.875rem;
        font-weight: 400;
        font-style: italic;
    }

        label .label-info {
            display: inline-block;
        }

    input, select, textarea {
        padding: 0.5rem;
        
        color: inherit;
        font-size: 1rem;
        /* font-family: monospace; */
    }

        input[disabled],
        textarea[disabled],
        select[disabled],
        input[readonly],
        textarea[readonly],
        select[readonly] {
            border-color: transparent !important;
            background: #f6f6f6;

            color: #aaa;
        }

.input-set {
    flex-grow: 1;

    display: flex;
    gap: 0.125rem;
}

    .input-set > div.input {
        flex: 1;
        margin: 0;
    }

        .input-set > div.input > select {
            width: 100%;
        }

.input-series {
    width: 100%;
    border: 0;
}

    .input-series .input-series-set {
        position: relative;

        padding-bottom: 1rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        margin-left: -0.75em;
        margin-right: -0.75rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #999;
    }

        .input-series .input-series-set fieldset + * {
            margin-top: 0;
        }

    .input-series.without-add-button {
        margin-bottom: -0.5rem;
    }

        .input-series.without-add-button .input-series-set:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }

    .input-series.without-dividing-lines {}
            
        .input-series.without-dividing-lines .input-series-set {
            border-bottom: 0;
        }

    .input-series.without-duplicate-labels {}
        
        .input-series.without-duplicate-labels .input-series-set {
            padding-bottom: 0;
            margin-bottom: 0.5rem;
        }

        .input-series.without-duplicate-labels .input-series-set:nth-child(n+2) label {
            display: none;
        }

    .input-series-add {
        margin-bottom: 1.5rem;
    }

        .input-series-set .input.with-input-series-add {
            display: flex;
            flex-direction: column;
        }

            .input-series-set .input.with-input-series-add .input-series-add {
                align-self: flex-start;
                order: 10;

                margin-top: 1rem;
            }

        .input-series-add-with-fields {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 0.125rem;

            margin-top: 2rem !important;
        }

            .input-series-add-with-fields > div.input {
                flex-grow: unset;
                
                min-width: 30%;
                margin-bottom: 0 !important;
            }

                .input-series-add-with-fields input:not([type="button"]),
                .input-series-add-with-fields select,
                .input-series-add-with-fields textarea {
                    flex-grow: 1;

                    width: unset;
                    border-color: #789;
                }

                    .input-series-add-with-fields input[disabled]:not([type="button"]),
                    .input-series-add-with-fields select[disabled],
                    .input-series-add-with-fields textarea[disabled] {
                        border-color: inherit;
                    }

                .input-series-add-with-fields select {
                    padding-right: 2rem;
                }

            .input-series-add-with-fields .input-series-add {
                margin: 0;
                min-width: 0;
            }

    .input-series .input-series-remove {
        position: absolute;
        right: -3.5rem;
        top: 0.5rem;
        
        border: 0;
        padding: 1rem;
        background: white;
        
        color: var(--colorRed);
        font-size: 2rem;
    }

        .input-series.without-duplicate-labels .input-series-set:nth-child(n+2) .input-series-remove {
            top: -1rem;
        }

        .input-series .input-series-set > .input-series-remove {
            display: none;
            pointer-events: none;
        }
    
    .input-series .input-series-remove .button-label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        
        display: inline-block;
        font-size: 1.5rem;
        line-height: 1;
        text-indent: .25em;
    }

    .input-series .input-series-set:hover {}

        .input-series .input-series-set:hover > .input-series-remove {
            display: flex;
            pointer-events: auto;
            cursor: pointer;
        }

        .input-series .input-series-set:hover > .input-series-remove:hover {
            color: black;
        }

.button {
    display: flex;
    align-content: baseline;

    padding: 0.5rem 1.5rem 0.4rem;
    border: 2px solid white;
    border-radius: 1rem;
    background-color: var(--colorCiffc);
    
    color: white;

    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
    font-size: 0.825rem;
    line-height: 1;
    font-family: inherit;
    letter-spacing: 0;
    text-transform: none;

    white-space: nowrap;
}

    .button [class*=fa-],
    .link [class*=fa-],
    a [class*=fa-] {
        margin: 0 0.425em -0.1em;
    }

.login-form {
    max-width: 400px;
    /* margin-inline: auto; */
}


.sitrep-display-prefixed {
    counter-increment: sitrep-display-section;
}
.sitrep-display-prefixed::before {
    content: counter(sitrep-display-section, upper-alpha) ": ";

    margin-right: 0.25em;
    padding: 0.125em 0.25em 0.025em;
    border-radius: 0.5rem;
    background: var(--colorPink);

    color: var(--colorCiffc);
    /* font-size: 1.2em; */
    font-weight: 700;
}


.form-sitrep {}

    .form-sitrep .form-status {
        display: flex;
        flex-wrap: wrap;
    }

        .form-status dt {}

            .form-status dt:after {
                content: ":";
            }

        .form-status dd {
            flex-grow: 1;

            min-width: 150px;
            margin-right: 2rem;
            border-bottom: 1px dotted grey;

            cursor: not-allowed;
        }

    .form-sitrep .form-wrapper {
        margin: 0;
        padding: 0;
        border: 0;
    }

    .form-sitrep .form-section {
        margin-bottom: 2rem;
        border: 1px solid #999;
    }
        .form-sitrep {
            counter-reset: sitrep-form-section;
        }
        .form-sitrep .form-section {
            counter-increment: sitrep-form-section;
        }
        .form-sitrep .form-section > legend:first-child:before {
            content: counter(sitrep-form-section, upper-alpha) ": ";
        
            margin-right: 0.25em;
            padding: 0.125em 0.25em 0.025em;
            border-radius: 0.5rem;
            background: var(--colorPink);
        
            color: var(--colorCiffc);
            font-size: 1.2em;
            font-weight: 700;
        }
        
    .form-section > p {
        margin-bottom: 0.5rem;

        line-height: 1.3;
        font-style: italic;
    }
        
        .form-section > legend {
            padding: 0 1rem 0 0.5rem;

            font-family: 'Libre Franklin', sans-serif;
            font-size: 1.2rem;
            line-height: 1.2;
            font-weight: 600;
        }

        .form-section fieldset {
            padding: 0;
            margin: 0;
            border: 0;
        }

            .form-section fieldset + * {
                margin-top: 1rem;
            }

            .form-section fieldset.sub-series {
                padding: 0.5rem 0.75rem 1rem;
                border: 1px solid #ccc;
            }

            .form-section fieldset legend {
                margin: 0.5rem 0 0;
                padding-right: 1rem;

                font-weight: 700;
            }

            .form-section fieldset legend ~ * label {
                font-weight: 400; 
            }

            .form-section fieldset.input {
                display: flex;
                flex-wrap: wrap;
                gap: 0.125rem;
            }

                .form-section fieldset.input > div.input {
                    width: 15%;
                }

                    .form-section fieldset.input > div.input.thin {
                        flex-grow: 1;
                        width: 5%;
                    }

                    .form-section fieldset.input > div.input.wide {
                        flex-grow: 1;
                    }

                .form-section fieldset.input > .spacer {
                    flex-grow: 1;
                    width: 100%;
                }

    .form-sitrep textarea {
        min-height: 5rem;
    }

    .is-locked input:not([type='submit']),
    .is-locked select,
    .is-locked textarea,
    .is-locked button:not([type='submit']),
    input:disabled,
    select:disabled,
    textarea:disabled,
    button:disabled {
        border: 0 !important;
        border: 2px solid #f6f6f6 !important;
        background: #f6f6f6 !important;

        color: #aaa !important;

        pointer-events: none !important;
    }

    .is-locked input[type='radio'],
    input[type='radio']:disabled {
        appearance: none !important;
        
        width: 0.75rem !important;
        height: 0.75rem !important;
        border-radius: 1rem !important;
    }

        .is-locked input[type='radio']:checked,
        input[type='radio']:checked:disabled{
            background-color: #aaa !important;
        }

.app-header {
    margin: 0;
    /* border-top: 0.25rem solid #422; */
    background: transparent;
    
    color: #345;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85rem;
    font-weight: 700;
    line-height: 1.75;

    z-index: 10;
}

    .app-header .container {
        position: relative;

        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 1rem .5rem;
    }

        .app-header h1 {
            margin: 0 0 0 0.5rem; /* to lining up left edge with first main nav link (all A tags have padding) */
            padding: 0;

            cursor: pointer;
        }
            .app-header h1 a {
               display: block;
            }
            .app-header h1 svg {
                width: 16rem;
                height: auto;
                margin-top: 0.25rem; /* slight adjustment to line up, SVG has built-in blank space at bottom */
            }

        .main-nav {
            display: block;
            width: 100%;
            
            font-size: 1rem;
        }
        .nav-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            margin: 0rem 0 1rem 0;
            padding: 0;

            color: #9C3321;
            font-weight: 400;

            list-style-type: none;
        }
            .nav-list.primary-list {
                width: 100%;
                margin: 1rem 0 0;

                color: #9C3321;
                font-weight: 600;
            }
            .nav-list.secondary-list {
                order: -1;
                z-index: 1;

                position: absolute;
                top: 1rem;
                right: 1rem;
                
                font-size: 0.85rem;
                margin-bottom: 0;
            }
            
            .app-header li a,
            .app-header li .link {
                position: relative;
                display: block;

                padding: 0.25rem 0.5rem;
                border: none;
                background: transparent;
                
                color: inherit;
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-decoration: none;

                white-space: nowrap;

                cursor: pointer;
            }

                .app-header li a.pill-shape {
                    margin-left: 0.625rem;
                    margin-right: -.5rem;
                }

                    .app-header li a.pill-shape.logged-in {
                        padding: 0 1rem;
                        margin-right: 0;
                        border-radius: 1.5em;
                        border: 1.5px solid transparent;
                        background-color: white;
                        
                        box-shadow: 0 0 2px #00000022;
                        color: var(--colorCiffc);
                        font-weight: 600;
                        text-transform: uppercase;                    
                    }

                        .app-header li:hover a.pill-shape.logged-in {
                            border-color: var(--colorCiffc);        
                        }
                .app-header li a svg {
                    opacity: .7;
                }

            .app-header li a:hover,
            .app-header li .link:hover {
                color: var(--colorCiffc);
            }

            .app-header li {
                position: relative;
            }

                .agency-sidebar-navigation {
                    position: absolute;
                    right: 0;

                    display: none;
                    flex-direction: column;
                    
                    list-style-type: none;
                    width: auto;
                    margin: 0;
                    padding: 0.25rem 0;
                    
                    background-color: var(--colorCiffc);
                    
                    color: white;
                    font-size: 1rem;
                    font-weight: 700;
                    text-align: right;
                    white-space: nowrap;
                    
                    opacity: 0.9;
                }

                    .app-header li:hover > .agency-sidebar-navigation {
                        display: flex;
                        
                        border-top: 0.825rem solid #fffaff; /* color of background gradient image at that point */
                    }

                        .agency-sidebar-navigation:before {
                            content: "";

                            position: absolute;
                            top: -1rem;
                            right: 1rem;

                            width: 0; 
                            height: 0; 
                            border-left: 1rem solid transparent;
                            border-right: 0.5rem solid transparent;
                            border-bottom: 1rem solid var(--colorCiffc);
                        }

                        .agency-sidebar-navigation > li {
                            width: 100%;
                            padding: 0.25rem 0 0.25rem 1rem;
                            border-top: 1px solid #ffffff66;
                        }

                            .agency-sidebar-navigation > li:first-child {
                                border-top: none;
                            }
                            
                            .agency-sidebar-navigation .link {
                                text-align: inherit;
                                width: 100%;
                            }

                            .agency-sidebar-navigation > li a:hover,
                            .agency-sidebar-navigation > li .link:hover {
                                color: black;
                            }
                
                /* Order of menu items: */
                .agency-sidebar-navigation .menu-item { order: 0; }
                
                .agency-sidebar-navigation .menu-item-overview { order: -99; }
                .agency-sidebar-navigation .menu-item-sitrep-input-national,
                .agency-sidebar-navigation .menu-item-sitrep-input-agency { order: -10; }
                .agency-sidebar-navigation .menu-item-input-instructions { order: -5; }    
                    /* everything else defaults to 0 order */
                .agency-sidebar-navigation .menu-item-logout { order: 99; }
                
                
                .menu-toggle {
                    display: none;
                    position: relative;
                    -webkit-appearance: none;
                    margin: auto .5rem auto auto;
                    padding: .5rem .75rem .25rem;
                    border: 1px solid currentcolor;
                    border-radius: .25rem;
                    background: none;
                    color: var(--colorCiffc);
                    line-height: 1;
                    font-size: 2rem;
                    cursor: pointer;
                }
                .menu-toggle:hover {
                    color: #000;
                }
                    .menu-toggle svg {
                        transition: .1s opacity;
                    }          
                    .menu-toggle .fa-xmark {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        opacity: 0;
                    }
                    .menu-on .fa-bars {
                        opacity: 0;
                    }
                    .menu-on .fa-xmark {
                        opacity: 1;
                    }
main {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    
    margin-bottom: 4rem;
}

    main > section.contentpage {
        flex-grow: 1;
    }

    main > section:first-child {
        margin: 0;
        padding: 1.5rem 0 2.5rem;
        background: white;
    }

    main b, main strong {
        font-weight: 700;
    }

    main em, main i {
        font-weight: 400;
    }

    main hgroup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    main h1, main h2 {
        margin-top: 3.0rem;
        margin-bottom: 1.5rem;

        color: #0f172a;
        font-family: 'Montserrat', sans-serif;
        font-size: 3rem;
        line-height: 1.15;
        font-weight: 700;
    }

        main h1:first-child, main h2:first-child { 
            margin-top: 0;
        }

    main h3 {
        margin-top: 2.0rem;
        margin-bottom: 0.5rem;

        color: #0f172a;
        font-family: 'Libre Franklin', sans-serif;
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 700;
    }

    main h4 {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;

        color: #0f172a;
        font-family: 'Libre Franklin', sans-serif;
        font-size: 1.35rem;
        line-height: 1.4;
        font-weight: 700;
    }

        main h2 + h3,
        main h2 + h4 {
            margin-top: -1rem;
        }

    main h5 {
        margin-top: 1rem;
        margin-bottom: 0.25rem;

        color: #3f475a;
        font-family: 'Libre Franklin', sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 700;
    }

    main ul,
    main ol {
        margin-bottom: 1.6rem;
    }

    main p {
        margin-bottom: 1.6rem;
    }

    main .back {
        display: block;
        margin: 3rem 0 0;

        font-weight: 700;
    }
    
.static-content {
    flex-grow: 1;
}

.footnotes {
    margin: 1rem 0 2rem;
    padding: 1rem 1rem 0;
    border-top: 1px solid #ddd;

    font-style: italic;
}

    .footnotes *:last-child {
        margin-bottom: 0;
    }

.warning-box {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 2rem;
    padding: 1rem;
    border: 2px solid #a17112;
    border-radius: .5rem;
    background-color: #fbf0da;
}
    .warning-box > h2:first-child {
       margin-block: 0 .5rem;
       
       font-size: 2rem;
    }
    .warning-box > * {
        color: #a17112;
    }
    .warning-box > *:last-child {
        margin-bottom: 0;
    }
    .warning-box.api-check ~ * {
        display: none;
    }

.table-wrapper {
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
	-ms-overflow-style: none;
	margin: 1rem auto;

	/* Scrolling Visual Cue */
	background: linear-gradient(to right, #fff 30%, rgba(255, 255, 255, 0)),
		linear-gradient(to right, rgba(255, 255, 255, 0), #fff 70%) 0 100%,
		radial-gradient(
		  farthest-side at 0% 50%,
		  rgba(0, 0, 0, 0.2),
		  rgba(0, 0, 0, 0)
		),
		radial-gradient(
			farthest-side at 100% 50%,
			rgba(0, 0, 0, 0.2),
			rgba(0, 0, 0, 0)
		  )
		  0 100%;
	background-repeat: no-repeat;
	background-color: #fff;
	background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
	background-position: 0 0, 100%, 0 0, 100%;
	background-attachment: local, local, scroll, scroll;
}

    .table-wrapper > table {
        /* for debugging */
        /* outline: 1px solid red; */
        /* outline-offset: -1px; */
        margin: 0 auto;
    }

table {
    /* 		table-layout: fixed; */
    width: 100%;
    border-collapse: collapse;
    max-width: 100%;
    overflow-x: scroll;
    
    margin: 1rem auto;
    border: 1px solid #ccc;

    box-shadow: var(--boxShadow);
}

    form label + table,
    form legend + table {
        margin-top: 0.125rem
    }

    table:empty {
        display: block;

        width: 100%;
        border: 1px solid #ccc;
        background: white;
    }

        table:empty:before {
            content: "";

            display: block;
            width: 100%;
            height: 2rem;
            margin-bottom: 2rem;
            background: #f9f6f9;
        }

    table thead {
        background-color: #f9f6f9;
    }

    table tfoot {
        border-top: 1px solid #ccc;

        font-weight: 700;
    }

        table tfoot .disclaimer {
            max-width: 1px;
            border-top: none;

            font-weight: 300;
            font-style: italic;
        }

    table th {
        color: inherit;
        font-weight: 700;
    }

        table thead th {
            color: #666;
            font-weight: 300;
            font-size: 0.8em;
            line-height: 1.25;
        }

    table tr:nth-child(even) {
        background-color: #fdfdfd;
    }

    table tbody + tbody {
        border-top: 1px solid #ccc;
    }

    table .border-left {
        border-left: 1px solid #ccc;
    }

    table td.nowrap { white-space: nowrap; }
        table td.nowrap:not(:first-child) { padding-left: 0.25rem; }
        table td.nowrap:not(:last-child) { padding-right: 0.25rem; }
        /* purposely LETTING the TH wrap on these "nowrap", since we expect EN and FR th labels to be too long, but we want PADDING to match the TDs */
        table th.nowrap:not(:first-child) { padding-left: 0.25rem; }
        table th.nowrap:not(:last-child) { padding-right: 0.25rem; }

    table td:empty:before {
        content: "-";

        color: #999;
    }

        table td.empty-integer:empty:before {
            content: "0";

            color: inherit;
        }

.table-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 1rem 0;
}

    .table-filter > div {
        margin: 0 1rem .5rem 0;
    }

        .table-filter > div.to-right {
            flex-grow: 1;

            text-align: right;
        }

    .table-filter + table {
        margin-top: 2em;
    }

.table-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

    .table-pagination label {
        margin: 0;
        padding: 0
    }

    .table-pagination select {
        width: auto;
        margin: 0 0.5rem;
    }

    .table-pagination span {
        margin: 0;
        padding: 0
    }


.table-sidebyside {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
}

    .table-sidebyside > div {
        flex-grow: 1;
    }

.siterep-archive, .siterep-archive ul {
    display: flex;
    flex-direction: column-reverse;
}

.siterep-archive ul ul {
    flex-direction: column;
}

.fires-charts {}

    .fires-charts .fire-chart-loading {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 1rem 0;
        width: 100%;
        min-height: 30vh;
        border: 1px solid #ccc;

        box-shadow: var(--boxShadow);
    }

    .fires-charts .container > h2 {
        width: 100%;
    }

    .fires-charts .fire-chart {}

        .fires-charts .fire-chart canvas {
            margin: 1rem 0;
            width: 100%;
            border: 1px solid #ccc;

            box-shadow: var(--boxShadow);
        }

.dashboard {
    flex-grow: 0;

    padding: 0 !important;
    background: transparent !important;
}

    .dashboard-row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch !important;
        
        /* width: 100%; */
    }

    .dashboard-column {
        display: flex;
        flex-direction: column;

        width: 25%;
        
        width: 100%;
        flex-basis: 25%;
        flex-grow: 1;
    }

        .dashboard-column.half {
            flex-grow: 1;
            flex-basis: 50%;
        }

    .dashboard-card {
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        flex-grow: 1;

        min-height: 8rem;
        margin: 0 0.3rem 0.6rem;
        padding: 1rem 0.5rem;
        border-radius: 0.5rem;
        background: white;

        overflow: hidden;

        box-shadow: var(--boxShadow);
    }

        .dashboard-card-titling {
            width: 35%;

            text-align: center;
        }

        .dashboard-card-summary {
            width: 70%;

            display: flex;
            flex-direction: column;
            justify-content: center;
        }

            .dashboard-card-summary > * {
                max-width: 100%;
                margin: 0 0 0.25rem;
            }

                .dashboard-card-summary-read-more {
                    margin: 0.5rem 0 0.25rem;
                    padding: 0;
                    list-style-type: none;
                }

                .dashboard-card-summary-read-more li {
                    margin: 0 0 0.25rem;
                    padding: 0;
                }

                .dashboard-card-summary-read-more a {
                    font-style: italic;
                }

                    .dashboard-card-summary-read-more a:hover {}

                    .dashboard-card-summary-read-more a svg {
                        margin-left: 0.25rem;
                    }

            .dashboard-card-summary *:last-child {
                margin-bottom: 0;
            }

    .dashboard-stat {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

        .dashboard-stat .stat-label {
            max-width: 80%;
            margin: 0;
            
            color: #999;
            font-weight: 500;
            font-size: 0.75rem;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: -0.025em;

            text-wrap: balance; /* where supported */
        }

        .dashboard-stat .stat-value {
            flex-grow: 1;

            display: flex;
            align-items: center;

            margin: 0;
            
            color: #54565A;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            font-size: 4.0rem;
            font-size: clamp(2rem, min(2rem + 5vw), 4.0rem);
            line-height: 1.0;
        }

        .dashboard-stat [class*="apl-lvl-"] {
            display: inline-block;
            padding: 0.125rem 2rem;
            margin: 0 auto 0.75rem;
            border-radius: 0.325em;
            width: auto;
            
            font-size: 6.0rem;
            font-size: clamp(2rem, min(2rem + 6vw), 6.0rem);
            line-height: 1.0;
        }

        .dashboard-stat .latest-sitrep-link {
            display: inline-block;
            margin: 0 auto 0.75rem;

            color: #54565A;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1;
            text-align: center;
        }

            .dashboard-stat .latest-sitrep-link-year {
                display: block;
            }

            .dashboard-stat .latest-sitrep-link:hover {
                color: black;
            }

    .dashboard-map {
        padding: 0;
    }

        .dashboard-map-canvas {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 100%;

            padding: 0;
        }

            .dashboard-map-canvas > * {
                flex-grow: 1;
                height: 40rem !important;

                border-radius: 0.25rem;
                overflow: hidden;
            }

        .dashboard-map-legend {
            position: absolute;
            top: 0.5rem;
            right: 1rem;
            max-width: 20%;
            min-width: 250px;
            padding: 1rem 0.5rem 0.75rem;
            border-radius: 0.25rem;
            background: #f3f9fc;

            z-index: 1000;
        }

            .map-title {
                width: 90%;
                margin: 0 auto;

                color: #54565A;
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 1.2rem;
                line-height: 1.1;
                text-align: center;
                text-wrap: balance;
            }

            .map-legend-footer {
                width: 90%;
                margin: 0.25rem auto 0;

                color: #54565A;
                font-size: 0.8rem;
                line-height: 1.5;
                font-style: italic;
                text-align: center;
                text-wrap: balance;
            }

            .map-legend {
                display: block;
                
                width: 80%;
                margin: 0.5rem auto 0;
                padding: 0;
        
                list-style-type: none;
            }

                .map-legend > li {
                    padding: 0.5rem 0 0.5rem;
                }
        
                    .map-legend .map-legend-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        width: 100%;
                        margin: 0;
                        border: 0;
                        background: transparent;
            
                        color: #54565A;
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 1.1;
                        text-align: left;
                    }

                        .map-legend .map-legend-item svg {
                            margin: 0 0.75rem 0 0;

                            font-size: 1.5rem;
                            cursor: pointer;
                        }
                        .map-legend .map-legend-item svg:focus {
                            color: #18b4cd;
                            outline: none;
                        }
                        
                        /* these COLORS should match the hexdec colors HARDCODED in the dashboard page MAP pins */
            
                        /*
                        .map-legend .soc-oc { color: #F5333F; }
                        .map-legend .soc-bh { color: #336667; }
                        .map-legend .soc-uc { color: #F9A123; }
                        */

                        /* JUN 2024, updated at client request, when MONITORed pseudo status added */

                        .map-legend .soc-oc { color: #e00; text-shadow: 0 0 0 #e00; }
                        .map-legend .soc-bh { color: #ed3; text-shadow: 0 0 0 #ed3; }
                        .map-legend .soc-uc { color: #00a2ff; text-shadow: 0 0 0 #00a2ff; }
                        .map-legend .soc-mon { color: #b3a; text-shadow: 0 0 0 #b3a; }

                    .map-legend ul {
                        margin: 0 0 0 3rem;
                        padding: 0;
                
                        list-style-type: none;
                    }

                        .map-legend ul .map-legend-circle {
                            display: inline-block;
                            
                            width: 20px;
                            text-align: center;
                        }

                            .map-legend ul .map-legend-ha {
                                margin-left: 0.5rem;
                                
                                font-size: 0.8rem;
                                line-height: 1.5;
                                font-style: italic;

                                color: #000c;
                            }
                            
                    .map-legend li.markers-hidden {
                        filter: grayscale(1);
                        opacity: 0.25;
                    }

    .dashboard-card > canvas {
        height: auto !important;
        width: 90% !important;
        margin: 0 0 1rem;
    }




.dashboard.agency {}

    .dashboard.agency .agency-status-summary {
        display: flex;
        flex-wrap: wrap;

        margin: 0.5rem auto;
        padding: 0;
        width: 90%;

        color: #9ab;
    }

        .dashboard.agency .agency-status-summary dt {
                margin: 0 0.5rem 0 0;
                padding: 0;

                font-weight: 300;
                text-align: right;
            }

                .dashboard.agency .agency-status-summary dt:after {
                    content: ":";
                }

            .dashboard.agency .agency-status-summary dd {
                margin: 0 1rem 0 0;
                padding: 0;

                font-weight: 700;
                text-align: left;
            }

                .dashboard.agency .agency-status-summary dd:not(:last-child) {
                    flex-grow: 1;
                }

    .dashboard.agency table {
        flex-grow: 1;

        margin: 0;  

        /* remove border and shadow on table, else would "double up" with card's own effects */
        border: 0;
        box-shadow: none;
    }

    .dashboard.agency .agency-sidebar-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 1.25rem auto 0;
        padding: 0;
        width: 90%;

        list-style-type: none;
    }

        .dashboard.agency .agency-sidebar-navigation li {
            display: block;
            line-height: 1;
        }

        .dashboard.agency .agency-sidebar-navigation a,
        .dashboard.agency .agency-sidebar-navigation .link {
            all: unset;
            appearance: none;
            
            display: block;
            margin: 0;
            padding: 0.25rem 1.5rem;
            border: 0;
            border-radius: 0.5rem;
            background: white;
            
            color: var(--colorCiffc);
            font-size: 1rem;
            font-weight: 700;
            line-height: 24px;
            text-decoration: none;

            box-shadow: var(--boxShadow);

            cursor: pointer;
        }

            .dashboard.agency .agency-sidebar-navigation a:hover,
            .dashboard.agency .agency-sidebar-navigation .link:hover {
                margin-top: 0.15rem;
                margin-bottom: -0.15rem;

                box-shadow: none;
            }
        

#approval-map-wrapper {
    position: relative;

    width: 100%;
    max-width: 600px;
    margin: 1rem auto;
}

    #approval-map {
        width: 100%;
    }

        #approval-map path {
            fill: var(--colorApprovalDefault);
            stroke: var(--colorApprovalDefaultStroke);

            cursor: not-allowed;
        }

            #approval-map .approved path { fill: var(--colorApprovalApprove); }
            #approval-map .editing path { fill: var(--colorApprovalEdit); }
            #approval-map .rejected path { fill: var(--colorApprovalReject); }
            #approval-map .submitted path { fill: var(--colorApprovalSubmit); }
            #approval-map .unsubmitted path { fill: var(--colorApprovalUnsubmitted); }

            #approval-map .approval-map-can-hover path {
                cursor: pointer;
            }

                #approval-map .approval-map-can-hover:hover path {
                    fill: var(--colorApprovalHover) !important;

                    transition: fill 0.2s;
                }

    #approval-map-legend {
        position: absolute;
        top: 10%;
        right: 0%;

        display: flex;
        flex-direction: column;
        
        margin: 0;
        padding: 0;

        list-style-type: none;
    }

        #approval-map-legend li {
            display: flex;
            align-items: center;

            margin: 0;
            padding: 0;

            font-weight: 400;
        }

            #approval-map-legend .swatch { 
                display: inline-block;
                margin-right: 0.5rem;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 0.25rem;
                background: var(--colorApprovalUnsubmitted);
            }

            #approval-map-legend .swatch.editing { background: var(--colorApprovalEdit); }
            #approval-map-legend .swatch.submitted { background: var(--colorApprovalSubmit); }
            #approval-map-legend .swatch.rejected { background: var(--colorApprovalReject); }
            #approval-map-legend .swatch.approved { background: var(--colorApprovalApprove); }


.approval-map-table {
    min-width: 250px;
}
#map {}

    .markerFireOutOfControl { z-index: 4; }
    .markerFireBeingHeld { z-index: 3; }
    .markerFireUnderControl { z-index: 2; }
    .markerFireMonitored { z-index: 1; }

    .map-popover {
        display: none;

        background: white;
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        height: 120px;

        color: black;
        font-size: 0.75rem;
        line-height: 1rem;
    }

        /*
        .map-popover.soc-oc { border: 2px solid #F5333F; }
        .map-popover.soc-bh { border: 2px solid #336667; }
        .map-popover.soc-uc { border: 2px solid #F9A123; }
        */

        /* JUN 2024, updated at client request, when MONITORed pseudo status added */

        .map-popover.soc-oc { border: 2px solid #e00; }
        .map-popover.soc-bh { border: 2px solid #ed3; }
        .map-popover.soc-uc { border: 2px solid #00a2ff; }
        .map-popover.soc-mon { border: 2px solid #b3a; }

        .map-popover.show {
            display: block;

            z-index: 200 !important;
        }

        .map-popover dl {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            width: 300px;
            margin: 0;
            padding: 0;
            
        }

            .map-popover.fr dl {
                width: 380px;
            }

            .map-popover dd {
                width: 40%;
                margin: 0;
                padding: 0.125rem 0;
                border-top: 1px solid #ccc;

                font-weight: 700;
                text-align: left;
            }

                .map-popover.fr dd {
                    width: 60%;
                }

                .map-popover dd:after {
                    content:":";
                }

            .map-popover dt {
                width: 60%;
                margin: 0;
                padding: 0.125rem 0;
                border-top: 1px solid #ccc;

                font-weight: 300;
                text-align: right;
            }

            .map-popover.fr dt {
                width: 40%;
            }

            .map-popover dl *:nth-child(1),
            .map-popover dl *:nth-child(2) {
                border-top: none;
            }

.calendar-year {
    display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 0 1rem;

    align-items: start;

    --calendarBorderWidth: 2px;
    --calendarBorderColor: #ddd;
}

    .calendar-month {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        margin: 0;
        padding: 0;

        border-color: var(--calendarBorderColor);
        border-style: solid;
        border-width: 0;
        border-top-width: var(--calendarBorderWidth);
        border-left-width: var(--calendarBorderWidth);

        list-style-type: none;
    }

        .calendar-month-name {
            width: 100%;
            border-bottom: 1px solid black;
        }
        
        .calendar-day {
            display: flex;
            
            margin: 0;
            padding: 0;

            width: 14%;
            flex-grow: 1;
            border-color: var(--calendarBorderColor);
            border-style: solid;
            border-width: 0;
            border-bottom-width: var(--calendarBorderWidth);
            border-right-width: var(--calendarBorderWidth);
            
            line-height: 1;
            text-align: center;
        }

            .calendar-day:not([class*="apl-lvl-"]) {
                background-color: white;
            }

            .calendar-day.calendar-day-empty {
                background-color: #eee;
            }

            .calendar-day .calendar-content {
                position: relative;

                flex-grow: 1;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;
                min-height: 2.5rem;

                text-decoration: none;
            }

                .calendar-content .calendar-date {
                    position: absolute;
                    top: 0.15rem;
                    left: 0.15rem;

                    font-size: 0.5rem;
                    text-align: left;
                }

                .calendar-content .calendar-level {
                    display: block;
                    padding-top: 0.75rem;

                    font-family: 'Libre Franklin', sans-serif;
                    font-weight: 600;
                }

.toast-popup {
    font-size: 0.875rem;

    box-shadow: none !important;
}

    .toast-popup.toast-notify {
        background-color: var(--colorLightBlue);

        color: var(--colorBlue);
    }

    .toast-popup.toast-error {
        background-color: var(--colorPink);

        color: var(--colorRed);
    }

    .toast-popup.toast-success {
        background-color: var(--colorLightGreen);

        color: var(--colorGreen);
    }

/*
#root > footer {
    padding: 1rem 0;
    border-top: 0.25rem solid #422;
    background: var(--colorCiffc);

    color: white;
    font-size: 1rem;
}

    #root > footer .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #root > footer p {
        margin: 0;
    }

    #root > footer .contacts {
        display: flex;
        gap: 1rem;

        margin: 0;
        padding: 0;

        font-size: 1.25rem;

        list-style-type: none;
    }
*/