/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
	html {
		font-size: 12px;
	}

	*,
	*:before,
	*:after {
		background: transparent;
		color: #000;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	/* main a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	} */

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	/* Template Specific */
	.skip-nav,
	.main-nav,
	.app-header,
	.table-pagination,
	.table-filter,
	.back,
	button,
	.form-submit-buttons .button-submit.published,
	#root > footer { display: none !important; }
	
	#root {
		background: #fff;
		border-top: 0;
	}

	.dashboard.agency .dashboard-column { flex-basis: auto; }
	
	.calendar-day .calendar-content::after { content: none; }
}