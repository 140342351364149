html {
    box-sizing: border-box;
    font-size: 16px;

    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100%;
}

body {
    font: 400 1rem/1.25 Helvetica, Arial, sans-serif;
    background: white;
    color: #000;

    position: relative;
    min-height: 100%;
    margin: 0;

    display: flex;
    flex-direction: column;
}

#root {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1;
}

*, *:before, *:after {
    box-sizing: inherit;
}


/* !Typography ============================== */

h1, .h1 { margin-bottom: .25em; font: 800 3.25em/1.1 Helvetica, Arial, sans-serif; }
h2, .h2 { margin-bottom: .5em; font: 600 2em/1.1 Helvetica, Arial, sans-serif; }
h3, .h3 { margin-bottom: .25em; font: 600 1.2em/1.1 Helvetica, Arial, sans-serif; }
h4, .h4 { font-size: 1.1em; font-weight: 600; line-height: 1.5; margin-bottom: .5em; }
h5, .h5 { font-size: 1.4em; line-height: 1.5; margin-bottom: .5em; }
h6, .h6 { font-size: 1.1em; line-height: 1.5; }

p { margin: 0 0 1em 0; }
p img { margin: 0; max-width: 100%; height: auto !important; }
p.lead { font-size: 21px; line-height: 1.25;  }

i, em { font-style: italic; }
b, strong { font-weight: 600; }
small, .small { font-size: 80%; }

/*	Blockquotes  */
blockquote, blockquote p { font-size: 17px; line-height: 24px; color: #777; }
blockquote { margin: 0 0 20px; padding: 10px 20px 0 15px; border-left: 5px solid #ddd; }
blockquote cite { display: block; font-size: 12px; color: #666; }
blockquote cite:before { content: "\2014 \0020"; }
blockquote cite a, blockquote cite a:visited, blockquote cite a:visited { color: #666; }



/* !Links */
a		{ color: inherit; outline: 0; }
a:hover	{ color: inherit; outline: 0;}
a:focus	{ color: inherit; outline: thin dotted; }
a:active { outline: 0; }

p a { line-height: inherit; }


/* !Lists */
ul, ol { padding: 0 0 0 25px; margin: 0 0 1em 0; }
ul ul, ul ol, ol ol, ol ul { margin-bottom: 0; }
ul ul { list-style: circle; }
ul { list-style: disc; }
ol { list-style: decimal; }
li { line-height: inherit; }
ul.unstyled { list-style: none; margin-left: 0; }


dl { margin-bottom: 1em; }
dl dt, dl dd { line-height: inherit; }
dl dt { font-weight: 500; }
dl dd { margin-left: 1em; }
dl dd:first-child { margin-left: 0; }

dd:after {
    /* http://lea.verou.me/2012/02/flexible-multiline-definition-lists-with-2-lines-of-css/ */
    content: '\D\A';
    white-space: pre;
}

/* !Images */
figure {
    margin: 0 0 1rem 0;
}
img {
    border: 0;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}
img.scale-with-grid {
    max-width: 100%;
    height: auto;
}
.img-left {
    float: left;
    margin: 0 2em 2em 0;
}
.img-right {
    float: right;
    margin: 0 0 2em 2em;
}

.svg-icon,
.icon-svg,
svg.icon,
.icon svg {
    width: 100%;
    height: 100%;
}
i.icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
}

/* !Other */
code, pre {
    padding: 0 3px 2px;
    font-family: Monaco, Andale Mono, Courier New, monospace;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
}
code {
    background-color: #eee;
    color: rgba(0, 0, 0, 0.75);
    padding: 1px 3px;
}
pre {
    background-color: #f5f5f5;
    display: block;
    padding: 8.5px;

    border: 1px solid #ddd;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

hr {
    clear: both;
    height: 0;
    margin: 1em 0;
    border: 0;
    border-bottom: 1px solid #ddd;
}

address {
    display: block;
    line-height: 18px;
    margin-bottom: 18px;
}

.lower { text-transform: lowercase; }
.upper { text-transform: uppercase; }


sub, sup {
    /* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
    font-size: 75%;

    /* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
    line-height: 0;

    /* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
    position: relative;

    /* Note that if you're using Eric Meyer's reset.css, this
     is already set and you can remove this rule */
    vertical-align: baseline;
}
sup {
    /* Move the superscripted text up */
    top: -0.5em;
}
sub {
    /* Move the subscripted text down, but only
     half as far down as the superscript moved up */
    bottom: -0.25em;
}

/* ! Helper classes  */
.hidden {
    display: none !important;
    visibility: hidden;
}
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
.invisible {
    visibility: hidden;
}
.clearfix:after {
    content: "";
    display: table;
    clear: both;
}


/* ! Buttons ============================== */
.button {
    display: inline-block;
    vertical-align: middle;
    min-width: 3.5em;
    padding: 0.7em 1em 0.6em;
    border: 1px solid #eb2e38;
    border-radius: 6px;
    background: white;

    color: #eb2e38;
    font-size: 0.675rem;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;

	transition: all .2s;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.button.disabled {
    color: #D6D3D1;
    border-color: #D6D3D1;
    cursor: default;
}

.button:not(.disabled):hover {
    border-color: transparent;
    background-color: #333;
    color: white !important;
}

.button:active,
.button.active {
    border-color: #b3d4fc;
}
.button:focus {
    outline: 1px dotted #666;
    outline-offset: 1px;
}
.button.disabled,
.button[disabled] {
	border-color: black;
    background-image: none;

    color: black;

    opacity: 0.2;
    box-shadow: none;
    pointer-events: none;
    cursor: default;
}
.button.outline {
    color: #eb2e38;
    border-color: currentColor;
    background-color: transparent;
}
.button.outline:hover {
    color: #333;
}

.button [class*="fa-"] {
	display: inline-block;
	margin-right: 0.25em;
}

.button [class*=fa-]{
    margin-right: 0.65em;
    margin-bottom: -0.1em;
}


/* ! FORMS ============================== */
form {
    margin-bottom: 2em;
}
fieldset {
    margin-bottom: 2em;
    
    min-width: 0;
}
fieldset > :last-child {
    margin-bottom: 0;
}
label,
legend {
    display: block;
    margin-bottom: auto;

    color: #333;
    font-family: "Source Sans Pro";
    font-size: 1em;
    line-height: 1.5;
	font-weight: 500;
}
.label-bold {
    font-weight: bolder;
}
label.inblock {
    margin-left: 1em;
    margin-right: 0.5em;
}

.required > label:after {
    content: " *";
}

input,
select,
textarea,
output {
    display: inline-block;
    margin: 0;
    padding: .35em .25em 0.25em;
    width: 100%;
    max-width: 100%;
    min-width: 50px; /* ensure the input fields (such as with class .w5) are not too small when on narrow breakpoints  */
    border-radius: 4px;
    border: 1px solid #D6D3D1;
    background: #fff;

    color: #333;
    font: 400 0.875rem/1.125 Helvetica, Arial, sans-serif;

    vertical-align: middle;
}

	.error > input,
	.error > select,
	.error > textarea,
	.error > output {
		border-color: #FB7185;
	}

input:focus,
select:focus,
textarea:focus,
output:focus {
    /* border-color: #eb2e38; */
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

[type="checkbox"],
[type="radio"] {
    margin-right: 0.5em;
}

select {
    /* padding: initial; */

    /* for custom selects: */
    padding: .35em .5em 0.45em;
    padding-right: 2.5em;
    border: 1px solid #D6D3D1;
    border-radius: 4px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #fff;
    background-repeat: no-repeat;
    background-size: auto 35%;
    background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpolygon fill='%23D6D3D1' points='0,10 50,90 100,10'/%3E%3C/svg%3E");
    background-position: 95% center;
    background-position: right .5em top 55%;

    cursor: pointer;
}

input.form-error,
select.form-error,
textarea.form-error {
    border-color: rgba(197, 61, 53, 0.4);
}

[class^='input-'] {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

	p.input-info {margin: 0 0 0.25em; font-size: 0.8em; color: #666;}
	p.input-info:empty { display: none; }

[type="checkbox"],
[type="radio"] {
    width: auto;
    height: auto;
    min-width: 0;
    padding: 0;
    margin: 0.2em 0.5em 0 0;

    line-height: initial;
    border: none;
    display: inline-block;
    vertical-align: text-top;

    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
}
.input-checkbox label,
.input-radio    label {
    display: inline-block;
    vertical-align: text-top;
    max-width: 90%; /*  to avoid dropping into new lines on narrow breakpoints. */
    font-weight: normal;
}

input[disabled],
textarea[disabled],
select[disabled],
input[readonly],
textarea[readonly],
select[readonly] {
    color: #aaa;
	border-color: #eee;
    cursor: not-allowed;
}

/* Multiple sets */
.fm-checkbox > label:first-child,
.fm-radio > label:first-child {
    display: block;
    font-weight: bolder;
}

.input-check-option,
.input-radio-option {
    display: block;
    margin-bottom: 0;

    /* Spread options into three columns */
    display: inline-block;
    vertical-align: text-top;
    width: 33%;
    min-width: 200px;
}
.input-check-option input,
.input-radio-option input {
    float: left;
}
.input-check-option label,
.input-radio-option label {
    display: block;
    margin: 0 1.5em;
    font-weight: normal;
}

.input-prefix {
    padding: .5em .25em .5em .5em;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: #eee;

    font-weight: bold;
}

/* DATE/TIME inputs */

.input-date  select { width: 31%; margin-right: 1%; }

.input-checkbox { padding: 2em 0 0 1em; }

.input-checkbox.checked-delete { padding: 0; }

/* RANGE inputs */

.input-range {
    display: inline-block;
    width: calc(100% - 100px);
}

.input-range__range {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d7dcdf;
    outline: none;
    padding: 0;
    margin: 0;
}
.input-range__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2c3e50;
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}
.input-range__range::-webkit-slider-thumb:hover {
    background: #035700;
}
.input-range__range:active::-webkit-slider-thumb {
    background: #035700;
}
.input-range__range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #2c3e50;
    cursor: pointer;
    -moz-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}
.input-range__range::-moz-range-thumb:hover {
    background: #035700;
}
.input-range__range:active::-moz-range-thumb {
    background: #035700;
}
.input-range__range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px #1abc9c;
}

.input-range__value {
    display: inline-block;
    position: relative;

    width: 60px;
    padding: 5px 10px;
    margin-left: 8px;
    border-radius: 4px;
    border: 1px solid #D6D3D1;
    background: white;

    color: black;
    line-height: 20px;
    text-align: center;
}

.input-range__value:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid #D6D3D1;
    border-bottom: 7px solid transparent;
    content: "";
}

::-moz-range-track {
    background: #d7dcdf;
    border: 0;
}


/* 	File Input Upload */
/* .js [type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
} */
[type="file"] + label {

}
[type="file"] + label output {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
[type="file"] + label output strong {
    margin-left: auto;

    color: #dcc;
    font-weight: 300;
    font-style: italic;
    text-transform: none;
}
[type="file"] + label output .icon {
    margin-top: -0.25em;
    margin-right: 0.25em;
}
[type="file"]:focus + label output,
[type="file"] + label:hover output {
    color: #eb2e38;
    border-color: currentColor;

    border-color: #035700;
    color: #035700;
}
[type="file"]:focus + label output strong,
[type="file"] + label:hover output strong {
    color: currentColor;
    font-weight: 600;
}
.no-js [type="file"] + label,
.no-js [type="file"] + label output {
    display: none;
}


/* 	Input Error Messages */
.error-message {
    margin-bottom: 0;
    padding: .25rem .5rem 0.5em;
    background: #FFF1F2;

	color: #933;
	font-style: italic;
}
.input-checkbox .error-message {
    margin-top: .25em;
}

.controls-group {
    clear: both;
    padding: 0;
    margin: 0 0 1rem 0;
}
.controls-group > .button {
    margin-bottom: .25rem;
}
.controls-group > .button + .button {
    margin-left: 1rem;
}
.controls-group > .next {
    float: right;
}

.form-row {

}

/* Form Module */
.fm-form {
    max-width: 600px;
}
.fm-form [type="url"],
.fm-form [type="tel"],
.fm-form [type="text"],
.fm-form [type="email"],
.fm-form [type="number"],
.fm-form [type="password"],
.fm-form select,
.fm-form textarea {
    width: 100%;
}

/* 	Display classes */
.block { display: block; }
.inline { display: inline; }
.inblock { display: inline-block; }

/* 	Alignment */
.left { text-align: left; }
.right { text-align: right; }
.center { text-align: center; }

/* Opacity levels */
.o0  { opacity:  0; }
.o10 { opacity: .1; }
.o20 { opacity: .2; }
.o30 { opacity: .3; }
.o40 { opacity: .4; }
.o50 { opacity: .5; }
.o60 { opacity: .6; }
.o70 { opacity: .7; }
.o80 { opacity: .8; }
.o90 { opacity: .9; }
.o100 { opacity: 1; }

/* Blocking	widths */
.w5  { width: 5%; }
.w10 { width: 10%; }
.w12 { width: 12.5%; }
.w15 { width: 15%; }
.w16 { width: 16.666%; }
.w20 { width: 20%; }
.w25 { width: 25%; }
.w30 { width: 30%; }
.w33 { width: 33.333%; }
.w35 { width: 35%; }
.w40 { width: 40%; }
.w45 { width: 45%; }
.w50 { width: 50%; }
.w55 { width: 55%; }
.w60 { width: 60%; }
.w66 { width: 66.666%; }
.w65 { width: 65%; }
.w70 { width: 70%; }
.w75 { width: 75%; }
.w80 { width: 80%; }
.w85 { width: 85%; }
.w90 { width: 90%; }
.w95 { width: 95%; }
.w100 { width: 100%; }

/* Lines clamping (truncating) */
.l1, .l2, .l3, .l4, .l5, .l6, .l7, .l8, .l9, .l10 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.l1  { -webkit-line-clamp: 1; }
.l2  { -webkit-line-clamp: 2; }
.l3  { -webkit-line-clamp: 3; }
.l4  { -webkit-line-clamp: 4; }
.l5  { -webkit-line-clamp: 5; }
.l6  { -webkit-line-clamp: 6; }
.l7  { -webkit-line-clamp: 7; }
.l8  { -webkit-line-clamp: 8; }
.l9  { -webkit-line-clamp: 9; }
.l10 { -webkit-line-clamp: 10; }


/* ! Content module columns */
.cke > .c2,
.cke > .c3,
.cke > .c4,
.cke > .c5,
.cke > .c6 { float: left;         margin-left: 5%; }

.cke > .c2 { width: 49%;   width: calc((100% - 5% * 1) / 2 - 1px); }
.cke > .c3 { width: 32%;   width: calc((100% - 5% * 2) / 3 - 1px); }
.cke > .c4 { width: 23.5%; width: calc((100% - 5% * 3) / 4 - 1px); }
.cke > .c5 { width: 18.4%; width: calc((100% - 5% * 4) / 5 - 1px); }
.cke > .c6 { width: 15%;   width: calc((100% - 5% * 5) / 6 - 1px); }

.cke > .c1,
.cke > .c-first {
    clear: both;
    margin-left: 0;
}
.cke:after {
    content: "";
    display: table;
    clear: both;
}

/* ! CSS columns */
.columns-2 { columns: 2 500px; column-gap: 3em; }
.columns-3 { columns: 3 250px; column-gap: 3em; }
.columns-4 { columns: 4 250px; column-gap: 3em; }


/* ! Debug Body Classes */
body.debug:after {
    content: attr(class);
    right: 0;
}
body::before,
body::after {
    position: fixed;
    top: 0px;
    display: inline-block;
    padding: 0 .5em;
    color: #ccc;
    background: #333;
    z-index: 1000;
    font-size: 10px;
}

/* ! Dev. Site indicator */
.debug-indicator {
    display: block;
    padding: .5em;
    background-color: #fc0;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 7px, #330 7px, #330 14px);
    text-align: center;
}
.debug-indicator p {
    display: inline-block;
    margin: 0;
    padding: .293em 1em;
    background-color: black;

    color: white;
    line-height: 1.4em;
    text-transform: uppercase;
    font-size: 12px;
    font-family: courier, serif;
    font-weight: normal;
    letter-spacing: 0.1em;
}
.debug-indicator span {
    font-size: 1.5em;
    line-height: 1;
    cursor: help;
}



/* ! Messages ============================== */
#flashMessage {

}
.message {
    position: relative;
    padding: 1em 1.5em 0.85em;
    margin-bottom: 1em;
    border: 1px solid #ffffff66;
	border-radius: 0.25rem;
    background-color: #F59E0B;

    color: white;
}
	.message.success { background-color: #65A30D; }
	.message.error {   background-color: #BE123C; }
	.message.info {    background-color: #3a82f5;}

	.message .icon { margin-right: 0.5em; width: 1.5em; height: 1.5em; }
		.message .icon > svg { display: block; width: 100%; height: 90%; }

	.message a {
	    color: inherit;
	    text-decoration: underline;
	}

	.message > :last-child {
	    margin-bottom: 0;
	}

	.message .close	{
	    float: right;
	    color: #000000;
	    font-size: 20px;
	    font-weight: bold;
	    line-height: initial;
	    text-shadow: 0 1px 0 #fff;
	    text-decoration: none;
	    opacity: 0.5;
	}
		.message .close:hover{
		    color: #000000;
		    text-decoration: none;
		    opacity: 0.75;
		}


/* ! Cards items */
.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 103%;
    margin: 1em -1.5% 0;
    padding: 0;
    list-style-type: none;
}
.card {
    width: 30%;
    margin: .8em 1.5%;
    padding: 0 1em 1em;
    background-color: white;
    box-shadow: 0 0 2px 2px rgba(0,0,0,0.05);

    display: flex;
    flex-direction: column;
}
.card .more {
    margin-top: auto; /* to push item to the end of flex axis */
    margin-bottom: 0;
    flex-grow: 0;
}
.card-img {
    display: block;
    width: 100%;
    margin: 0;
    transition: all .5s ease;
}
.card-link {
    display: block;
    width: calc(100% + 2em);
    margin: 0 -1em 1em;
    overflow: hidden;
}
.card-link:hover .card-img {
    transform: scale(1.1);
}

/* 	for 4, 8, 12, 4n… */
.card:nth-last-child(2):first-child,
.card:nth-last-child(2):first-child ~ .card,
.card:nth-last-child(4n):first-child,
.card:nth-last-child(4n):first-child ~ .card {
    width: 45%;
    margin-left: 2%;
    margin-right: 2%;
}


/* !Media grid ============================== */
.media-grid {
    margin-left: -20px;
    margin-bottom: 0;
}
.media-grid:before, .media-grid:after {
    display: table;
    content: "";
}
.media-grid:after {
    clear: both;
}
.media-grid li {
    display: inline;
}
.media-grid a, .media-grid div.group {
    float: left;
    padding: 4px;
    margin: 0 0 20px 20px;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.media-grid a img {
    display: block;
}
.media-grid a:hover {
    border-color: #7a2;
    box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}
.media-grid .caption {
    display: block;
}



/* !Tabs (activate in tabs.js) ============================== */
.tabs-nav {
    display: block;
    margin: 0;
    padding: 0;
}
.tabs-nav li {
    display: block;
    width: auto;
    padding: 0;
    float: left;
    margin-bottom: 0;
}
.tabs-nav li a {
    display: block;
    text-decoration: none;
    width: auto;
    min-height: 34px;
    padding: 10px 20px;
    border: solid 1px #ddd;
    border-width: 1px 1px 0 0;
    margin: 0;
    background: #f6f6f6;
    font-size: 13px;
}
.tabs-nav li a:hover {
    background: #f0f0f0;
}
.tabs-nav li a.active {
    background: #fff;
    position: relative;
    padding-bottom: 11px;
    margin-bottom: -1px;
    border-left-width: 1px;
    margin: 0 0 -1px -1px;
    color: #111;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.tabs-nav li:first-child a.active {
    margin-left: 0;
}
.tabs-nav li:first-child a {
    border-width: 1px 1px 0 1px;
    border-top-left-radius: 3px;
}
.tabs-nav li:last-child a {
    border-top-right-radius: 3px;
}

.tabs-content { margin: 0; padding: 2em; border: 1px solid #ddd; display: block; list-style: none; clear: both;}
.tabs-content > .tab { display:none; }
.tabs-content > .tab.active { display: block; }



/* !Tables	============================== */
.table-wrap {
    max-width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
}
table {
    /* 		table-layout: fixed; */
    width: 100%;
    margin-bottom: 2em;
    padding: 0;
    font-size: inherit;
    border-collapse: collapse;

    max-width: 100%;
    overflow-x: scroll;
}
table th,
table td {
    padding: 0.625rem 0.625rem 0.5rem;
    line-height: 1.125;
    text-align: left;
}
table th {
    vertical-align: bottom;

    font-weight: 600;
}

table thead {
    /* border-bottom: 2px solid #ccc; */
}

table tfoot {
    /* border-top: 2px solid #ccc; */
    /* border-bottom: 2px solid #ccc; */
}

table thead:nth-child(n+2) th {
    border-top: 1px solid #ddd;
}
table td {
    vertical-align: top;
    border-top: 1px solid #ddd;
}
table tbody th {
    vertical-align: top;
}

/* 	table types: .condensed	and .bordered */
.condensed th,
.condensed td {
    padding: .25em .25em .2em;
}
.bordered {
    border: 1px solid #ddd;
}
.bordered th + th,
.bordered td + td,
.bordered th + td {
    border-left: 1px solid #ddd;
}

/* 	sortable and zebra-striped tables */
.striped tbody tr:nth-child(odd) td,
.striped tbody tr:nth-child(odd) th {
    background-color: #f9f9f9;
}
.striped tbody tr:hover td,
.striped tbody tr:hover th {
    background-color: #f5f5f5;
}
table .header {
    cursor: pointer;
}
table .header:after {
    content: "";
    float: right;
    margin-top: 7px;
    border-width: 0 4px 4px;
    border-style: solid;
    border-color: #000 transparent;
    visibility: hidden;
}
table .headerSortUp,
table .headerSortDown {
    background-color: #85c446;
    background-color: rgba(133,196,70,.3);
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}
table .header:hover:after {
    visibility: visible;
}
table .headerSortDown:after,
table .headerSortDown:hover:after {
    visibility: visible;
    opacity: 0.6;
}
table .headerSortUp:after {
    border-bottom: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #000;
    visibility: visible;
    box-shadow: none;
    opacity: 0.6;
}


/* !Pagination	============================== */

.pagination-links {
    clear: both;

    display: flex;
    justify-content: center;

    margin: 0.5rem 0;
    padding: 0;
    overflow: hidden;
    user-select: none;
}

.pagination-links li {
    display: block;
    float: left;
    margin: 0 0.25em;
    padding: 0.25em 0;
}

.pagination-links a,
.pagination-links .current {
    display: block;
    padding: 0.4em 0.75em;
    border-radius: .25em;
	border: 1px solid #D6D3D1;

    font-weight: 600;
	text-decoration: none;

	transition: all .2s;
}

.pagination-links a:hover {
    background-color: #035700;
    color: white;
}

.pagination-links li.active,
.pagination-links li.current {

}

.pagination-links .active a,
.pagination-links .current {
    background-color: transparent !important;

    color: #035700;
	text-decoration: none;
}

.pagination-links .disabled a,
.pagination-links .disabled a:hover {
    background-color: transparent;

    color: #dcc;
    font-weight: 400;
}

.pagination-links li:first-child a {}

.pagination-links li:last-child a {}

.pagination-links li.pagination-select {
    margin: 0 0.5em;
}

    .pagination-links li.pagination-select select {
        width: auto;
    }



/* ! Slick Slider default styles ============================== */
.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir="rtl"] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Slick Slider additional project specific styles */
.slick-arrow {
    position: absolute;
    top: 0;
    width: 5%;
    height: 100%;
    text-indent: -20em;
    overflow: hidden;
    border: none;
    color: #eee;
    background-color: rgba(255,255,255,0);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% auto;
    transition: all .2s;
    opacity: .5;
    cursor: pointer;
    z-index: 100;
}
.slick-arrow:hover {
    background-color: rgba(255,255,255,.5);
}
.slick-prev { left: 0;  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 100"><polygon fill="%23cccccc" points="50,14.4 14.4,50 50,85.6 50,100 0,50 0,50 50,0"/></svg>'); }
.slick-next { right: 0; background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 100"><polygon fill="%23cccccc" points="0,14.4 35.6,50 0,85.6 0,100 50,50 50,50 0,0"/></svg>'); }

/* Banner dots */
.slick-dots {
    list-style: none;
    position: absolute;
    bottom: 1em;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    left: 0; right: 0;
    line-height: 1;
}
.slick-dots > li {
    display: inline-block;
    margin: 0 .25em;
    text-align: center;
}
.slick-dots > li > button {
    display: block;
    border: 1px solid rgba(100,100,100,.90);
    border-radius: 100%;
    background: none;
    text-indent: -10em;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.slick-dots > li:hover > button,
.slick-dots > li.slick-active > button {
    background-color: rgba(0, 0, 0, .5);
}

/* ! Magnific Popup */
.default-popup {
    position: relative;
    background: #fff;
    padding: 20px;
    width: auto;
    max-width: 560px;
    margin: 20px auto;
}

/* ! Tooltips */
[data-tooltip] {
    position: relative;
    text-decoration: underline;
    text-decoration-style: dashed;
}
[data-tooltip]:before {
    opacity: 0;
    transition: opacity .2s;
}
[data-tooltip]:hover:before {
    content: attr(data-tooltip);
    display: block;
    position: absolute;
    bottom: 100%;
    left: 1em;
    transition: all .2s;
    padding: .5em .75em;
    background-color: #333;
    color: #fefefe;
    border-color: currentColor;
    border-radius: .5rem;
    border-bottom-left-radius: 0;
    font-size: small;
    font-weight: 100;
    line-height: 1.25;
    letter-spacing: normal;
    min-width: 200px;
    max-width: 400px;
    box-shadow: 0 0 8px 2px rgba(0,0,0,.1);
    opacity: 1;
}
